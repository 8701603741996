<section class="pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="col text-center">
 <h2>About</h2>              
      </div>
    </div>
    <article class="row pt-4 mt-4">

      <div class="col-md-4 bg-green">
        <img alt="Core Competencies" class="img-fluid my-4" src="../../assets/images/icon-core-competencies.svg">
        <h3>Core Competencies</h3>
    <p> Semi-Truck Transportation including General Freight Trucking, Long-Distance and Local, Truckload and Less than Truckload, Over the Road </p>
      </div>
      <div class="col-md-4 bg-green">
        <img alt="Past Performance" class="img-fluid my-4" src="../../assets/images/icon-past-performance.svg">
        <h3>Past Performance</h3>
    <p>DLM Logistics Inc moved thousands of loads for customers all over 48 states partnering with well known names in the industry like C.H. Robinson, J.B. Hunt, XPO, Coyote, Mainfreight…</p>
    <p>DLM Logistics Inc is especially proud of partnering with GE Healthcare for delivering their equipment worth millions of dollars to the hospitals all over USA and performing “White Glove” deliveries, where customers don’t have to touch the product.</p>
      </div>
      <div class="col-md-4 bg-green">
        <img alt="Differentiator" class="img-fluid my-4" src="../../assets/images/icon-differentiator.svg">
        <h3>Differentiator</h3>
    <ul>
      <li>We react quickly to the change of customer’s demand</li>
      <li>Personalized customer service</li>
      <li>Excellent safety record</li>
      <li>Professional experience from pick up of your product to delivery at its destination</li>
      <li>Able to expedite any size load – from one skid to a full 53’ Semi Van</li>
      <li>One of the highest “on-time delivery” in the industry</li>
    </ul>
      </div>
    </article>
  </div>
</section>
