import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

constructor(private http: HttpClient) { }

  private env:string = environment.api;

  getProductGroups(email: string):Observable<File>{
    return this.http.post<File>(this.env + 'api/Subscriber/' + email, null, {
      headers: new HttpHeaders({'Accept': 'application/octet-stream'}),
      responseType: 'blob' as 'json'
    });
  }


}
