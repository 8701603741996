<section class="hero pt-5 pb-1 mt-0 align-items-center d-flex bg-dark">
	<div class="container">
		<div class="row  justify-content-start align-items-center d-flex  h-100 ">
			<div class="col-12 col-md-8  h-50 ">
				<h1 class="text-white mb-2 mt-5">DLM Logistics Inc</h1>
				<h3 class="lead  text-white mb-3">Privately owned trucking company for semi truck transportion in the USA.</h3>
				<div class="input-group input-group-lg">
					<input type="email" class="form-control" placeholder="Enter your email..."  required [(ngModel)]="mail"
					aria-label="Enter your email..." aria-describedby="button-addon2">
					<div class="input-group-append">
						<button class="btn btn-dark bg-green btn-round btn-lg btn-rised" (click)="downloadFile()"
						type="button" id="button-addon2">Download Capability Statement 
						<i class="fas fa-arrow-right mr-md-2" aria-hidden="true"></i></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="pt-4 pb-0">
	<div class="container">
		<article>
			<h2>Company Info</h2>
			<p>DUNS# 036624287<br>
				CAGE: 8QNQ3<br>
				NAICS Codes:<br>
				484110 - General Freight Trucking, Local<br>
				484121 - General Freight Trucking, Long-Distance, Truckload<br>
				484122 - General Freight Trucking, Long-Distance, Less Than Truckload<br>
				484220 - Specialized Freight (except Used Goods) Trucking, Local<br>
				484230 - Specialized Freight (except Used Goods) Trucking, Long-Distance</p>
		</article>
		<article>
			<h2>Registrations and Licenses</h2>
			<ul>
				<li>MC# 083309</li>
				<li>US DOT# 311 22 57</li>
				<li>EIN# 81-2525051</li>
				<li>SCAC Code: DLMH</li>
			</ul>
		</article>
		<article>
			<h2>Insurance</h2>
			<ul>
				<li>Commercial Insurance – Limits can be raised if requested</li>
				<li>Commercial General Liability $1,000,000 per occurrence</li>
				<li>Motor Truck Cargo $100,000</li>
			</ul>
		</article>
		<article class="bg-green">
			<h2>Core Competencies</h2>
			<div class="row align-items-center">
				<div class="col-2 px-0 text-center"> <img src="../assets/images/icon-core-competencies.svg" class="img-fluid w-100 px-5" alt="core-competencies"> </div>
				<div class="col-10">
					<p class="lead"> Semi-Truck Transportation including General Freight Trucking, Long-Distance and Local, Truckload and Less than Truckload, Over the Road </p>
				</div>
			</div>
		</article>
		<article class="bg-green">
			<h2>Past Performance</h2>
			<div class="row align-items-center">
				<div class="col-2 px-0 text-center"> <img src="../assets/images/icon-past-performance.svg" class="img-fluid w-100 px-5" alt="past-performance"> </div>
				<div class="col-10">
					<p class="lead">DLM Logistics Inc moved thousands of loads for customers all over 48 states partnering with well known names in the industry like C.H. Robinson, J.B. Hunt, XPO, Coyote, Mainfreight…</p>
					<p class="lead">DLM Logistics Inc is especially proud of partnering with GE Healthcare for delivering their equipment worth millions of dollars to the hospitals all over USA and performing “White Glove” deliveries, where customers don’t have to touch the product.</p>
				</div>
			</div>
		</article>
		<article class="bg-green">
			<h2>Differentiator</h2>
			<div class="row align-items-center">
				<div class="col-2 px-0 text-center"> <img src="../assets/images/icon-differentiator.svg" class="img-fluid w-100 px-5" alt="differentiator"> </div>
				<div class="col-10">
					<ul>
						<li class="lead">We react quickly to the change of customer’s demand</li>
						<li class="lead">Personalized customer service</li>
						<li class="lead">Excellent safety record</li>
						<li class="lead">Professional experience from pick up of your product to delivery at its destination</li>
						<li class="lead">Able to expedite any size load – from one skid to a full 53’ Semi Van</li>
						<li class="lead">One of the highest “on-time delivery” in the industry</li>
					</ul>
				</div>
			</div>
		</article>
	</div>
</section>