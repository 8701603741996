<section class="pt-4 pb-0">
	<div class="container">
		<article>
			<h2>Contact</h2>
			<p>Nenad (Ned) Radosavljevic – President<br>
				<a href="tel:(872)255-0007">(872)255-0007</a><br>
				<a href="mailto:ned@dlmlogisticsinc.com">ned@dlmlogisticsinc.com</a></p>
			<p>720 Plainfield Rd Ste 105A<br>
				Willowbrook, IL, 60527, USA</p>
		</article>
	</div>
</section>
