<section class="pt-4 pb-0">
	<div class="container">
		<article>
			<h2>SMS Terms and Conditions</h2>
			<p>Effective date: March 1, 2023</p>
      <p>By consenting to receive text message from or on behalf of DLM Logistics Inc to the cellular phone number you provide to us, you agree to these Terms and Conditions and Privacy Policy. By giving your consent, you are confirming you are at least 18 years old.</p>
      <p>
        Depending on your relationship with us and consents received, DLM Logistics Inc may send short message service, multimedia message service or other similar text messages (collectively, text messages), for transactional and/or marketing purposes. These text messages are used to: (1) provide you with requested information; (2) provide updates on freight movement; (3) resolve concerns and issues related to your transactions; (4) communicate about credit collection; (5) send advertising or marketing materials. Message frequency may vary.
      </p>
      <p>
        You may provide DLM Logistics Inc with consent to send you text messages through communications or transactions with us (e.g. by initiating or agreeing to receive text message communications with our agents). Consent to receive text messages is not required as a condition of purchasing any goods or services. You may opt-out of these communications at any time by emailing

        <a href = "mailto: SMS@dlmlogisticsinc.com ">SMS@dlmlogisticsinc.com </a>
        or the agent assigned to your account.
      </p>
      <p>
        DLM Logistics Inc does not have a separate charge for this service; however message and data rates may apply from your mobile carrier.
      </p>
      <p>
        DLM Logistics Inc may modify these Terms from time to time with or without notice to you. Unless otherwise indicated, modifications will be effective on the date they are posted on this page or any successor page and supersede prior Terms.
      </p>
      <p>
        Please notify DLM Logistics Inc immediately if you change your mobile number. We are not liable for any communication or transmission of information by text which happens because you did not report changes to your mobile number.
      </p>
      <b>SMS Terms and Conditions </b>
      <ul>
        <li><b>Acceptance of Terms: </b>By using our website, you agree to these Terms and Conditions. </li>
        <li><b>Changes to Terms: </b>We may update these Terms at any time. Check for updates periodically. </li>
        <li><b>Description of Services: </b>We offer logistics, storage, and transportation services. </li>
        <li><b>Message Frequency: </b>By opting into SMS, you agree to receive notifications; message volume may vary (1 to 1500 messages). </li>
        <li><b>Potential Fees: </b>Message/data rates may apply based on your mobile provider. </li>
        <li><b>Opt-In/Opt-Out: </b>Opt-in via our website and opt-out by replying "STOP" or through our Contact Us page. </li>
        <li><b>Donations: </b>We do not currently solicit donations via SMS. </li>
        <li><b>Intellectual Property: </b>Content on our website is protected by copyright and trademarks. </li>
        <li><b>User-Generated Content: </b>By submitting content, you grant us a license to use it across platforms. </li>
        <li><b>Privacy Policy: </b>Review our privacy policy to understand how we handle your data. </li>
        <li><b>Disclaimer: </b>We make no guarantees about the accuracy of the content on our site. </li>
        <li><b>Contact Info: </b>For questions, contact us at (872)255-0007 or email us at info@dlmlogisticsinc.com. </li>
      </ul>
    </article>
	</div>
</section>
