import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { saveAs } from 'file-saver';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  mail: string = ''

  constructor(private api: ApiService) { }

  ngOnInit() {
  }

  downloadFile(){
    if(this.mail != ''){
      this.api.getProductGroups(this.mail).subscribe(
        (res) => {
          var blob = new Blob([res], {type: "application/pdf;charset=utf-8"});
          saveAs(blob, "DLM Capability statement.pdf");
        },
        (error) => {
          console.log('getPDF error: ',error);
        }
      );
    }
    
  }

}
